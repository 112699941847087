import { DcrImportLog } from '@/models/Entities/Imports/DcrImportLog';
import { formatDate, formatTime } from '@/services/formatService';

// TODO: add staff
export class DcrImportLogTableData {
  entityId: number;
  dateCertified: Date | null;
  totalSaved: number;
  totalErrors: number;
  importDate: string;
  importTime: string;

  constructor(dcrImport: DcrImportLog) {
    this.entityId = dcrImport.id;
    this.importDate = formatDate(dcrImport.createDateTime);
    this.dateCertified = dcrImport.dateCertified;
    this.importTime = formatTime(dcrImport.createDateTime);
    this.totalSaved = dcrImport.totalSaved;
    this.totalErrors = dcrImport.totalErrors;
  }
}
