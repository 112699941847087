
import { Component, Mixins, Vue } from 'vue-property-decorator';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import TableFilterComponent from '@/components/Table/TableFilterComponent.vue';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import { DcrImportLogTableData } from '@/components/Table/DCR/DcrImportLog/DcrImportLogTableData';
import { DcrImportLog } from '@/models/Entities/Imports/DcrImportLog';
import { SearchDcrImportLogDto } from '@/models/Dtos/searchDcrImportLogDto';
import { DcrImportLogDataProvider } from '@/components/Table/DCR/DcrImportLog/DcrImportLogDataProvider';
import { dcrImportLogTableConfigBuilder } from '@/components/Table/DCR/DcrImportLog/DcrImportLogTableConfigBuilder';
import Routes from '@/router/Routes';
import FetchMixin from '@/mixins/FetchMixin';
import { formatDate } from '@/services/formatService';

@Component<DcrImportPage>({
  components: {
    AsyncTableComponent,
    TableFilterComponent,
    QuickFilterComponent,
    LinkElementComponent,
    IconComponent
  }
})
export default class DcrImportPage extends Mixins(Vue, FetchMixin) {
  routes = Routes;
  latestDateTime = '...';
  searchService = new TableSearchService<
    DcrImportLogTableData,
    DcrImportLog,
    never,
    SearchDcrImportLogDto
  >(
    this.$store,
    new DcrImportLogDataProvider(this.$store),
    {},
    // TODO: DESC might be preferable
    { defaultSortProperty: 'createDateTime', isAscendingSortOrder: false },
    dcrImportLogTableConfigBuilder,
    {
      filters: [],
      quickFilters: []
    },
    undefined
  );

  mounted(): void {
    this.queryLatestDate();
    this.searchService.queryData();
  }

  async queryLatestDate(): Promise<void> {
    const date = await this.getLatest('dcr-import-log', DcrImportLog);
    if (date) {
      this.latestDateTime = formatDate(date);
    }
  }
}
