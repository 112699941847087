var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "p-4 bg-gray-100" }, [
    _c(
      "div",
      { staticClass: "inline-flex" },
      [
        _c(
          "router-link",
          { attrs: { to: { name: _vm.routes.REPORTS_DASHBOARD } } },
          [
            _c("h3", { staticClass: "pt-4 pl-8 mr-2 text-gray-500" }, [
              _vm._v("Reports /"),
            ]),
          ]
        ),
        _c("h3", { staticClass: "pt-4 mr-2" }, [_vm._v("DCR Import")]),
      ],
      1
    ),
    _c("div", [
      _c(
        "div",
        { staticClass: "inline-flex" },
        [
          _c("icon-component", {
            staticClass: "mt-5 ml-8",
            attrs: { name: "calendar", fill: "white", width: 14, height: 14 },
          }),
          _c("h5", { staticClass: "pt-4 pl-2 mr-2 text-gray-500" }, [
            _vm._v(" Last updated " + _vm._s(_vm.latestDateTime) + " "),
          ]),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "p-4 mt-8 bg-white border border-gray-200 rounded" },
      [
        _c(
          "async-table-component",
          _vm._g(
            _vm._b(
              {
                attrs: {
                  isColumnEditable: true,
                  isFilterable: true,
                  isSortable: true,
                  canDownloadCSV: true,
                  localStorageKey: "dcr-imports-table",
                  canEditPaginatedRows: "true",
                  emptyTableMessage: "0 DCR Imports",
                },
              },
              "async-table-component",
              _vm.searchService.tableProps,
              false
            ),
            _vm.searchService.tableEventListeners
          )
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }