import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import LinkElementComponent from '@/components/Table/Elements/LinkElementComponent.vue';
import TextWithColorElementComponent from '@/components/Table/Elements/TextWithColorElementComponent.vue';
import { TableConfigBuilder } from '../../models/TableConfigBuilder';
import { DcrImportLogTableData } from './DcrImportLogTableData';
import { DcrImportLog } from '@/models/Entities/Imports/DcrImportLog';
import { SearchDcrImportLogDto } from '@/models/Dtos/searchDcrImportLogDto';

export const dcrImportLogTableConfigBuilder: TableConfigBuilder<
  DcrImportLogTableData,
  DcrImportLog,
  never,
  SearchDcrImportLogDto
> = (): ITableConfiguration<DcrImportLogTableData, DcrImportLog, never> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Import Date',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowData: DcrImportLogTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.importDate,
              linkTo: {
                name: Routes.VIEW_DCR_DETAILS,
                params: { dcrImportId: rowData.entityId }
              }
            };
          }
        },
        sortKey: 'createDateTime'
      },
      {
        columnTitle: 'Import Time',
        render: 'importTime',
        sortKey: 'createDateTime'
      },
      {
        columnTitle: 'Date Certified',
        render: 'dateCertified',
        sortKey: 'dateCertified'
      },
      {
        columnTitle: 'Total Imported',
        render: 'totalSaved',
        sortKey: 'totalSaved'
      },
      {
        columnTitle: 'Errors',
        render: {
          componentRef: TextWithColorElementComponent,
          componentProps: (
            rowData: DcrImportLogTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.totalErrors,
              color: !rowData.totalErrors ? 'black' : 'val-red'
            };
          }
        },
        sortKey: 'totalErrors'
      }
    ]
  };
};
