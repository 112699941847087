import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { DcrImportLogTableData } from './DcrImportLogTableData';
import { TableDataProvider } from '../../models/TableDataProvider';
import { DcrImportLog } from '@/models/Entities/Imports/DcrImportLog';
import { SearchDcrImportLogDto } from '@/models/Dtos/searchDcrImportLogDto';

export class DcrImportLogDataProvider extends TableDataProvider<
  DcrImportLogTableData,
  DcrImportLog,
  SearchDcrImportLogDto
> {
  protected transformer = (t: DcrImportLog): DcrImportLogTableData =>
    new DcrImportLogTableData(t);

  protected queryHandler = async (
    searchDto: SearchDcrImportLogDto
  ): Promise<SearchResponseDto<DcrImportLog>> => {
    const results: SearchResponseDto<DcrImportLog> = await this.store.dispatch(
      'materializedModule/fetchWithSelectParameters',
      { path: 'dcr-import-log', query: searchDto, model: DcrImportLog }
    );
    return results;
  };
}
